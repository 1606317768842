import { useState } from "react";
import { OutlinedInput, Select, MenuItem, FormControl } from "@mui/material";

import useScrollTo from "../hooks/useScrollTo";

const PhoneCall = ({ state, dispatch }) => {
  const [phoneCallAppointment, setPhoneCallAppointment] = useState(
    state.phoneCallAppointment || []
  );

  const phoneCallAppointmentOptions = [
    "Between 9:00 to 11:00 am",
    "Between 12:00 to 2:00 pm",
    "Between 2:00 to 5:00 pm",
  ];

  useScrollTo(0, 0, "smooth");

  const onPhoneCallAppointmentChange = ({ target }) => {
    setPhoneCallAppointment(target.value);
    dispatch({ type: "PHONE_CALL_APPOINTMENT", payload: target.value });
  };

  return (
    <>
      <h3 className="text-center phone-call-appointment-header">
        In case you want to give us a call you before booking or to book you for
        an appointment, please give us a call on 0113 468 9593
      </h3>
      <label id="phone-call-appointment-label">
        Please select the time you would like us to call you back
      </label>
      <p className="guide-label">You can select as many options as desired.</p>
      <FormControl
        sx={{
          m: 0.2,
          width: "100%",
          border: "2px solid black",
          borderRadius: "11px",
          bgcolor: "white",
          marginTop: "15px",
        }}
      >
        <Select
          labelId="phone-call-appointment-label"
          id="phone-call-appointment"
          multiple
          value={phoneCallAppointment}
          onChange={onPhoneCallAppointmentChange}
          input={<OutlinedInput label="Name" />}
        >
          {phoneCallAppointmentOptions.map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <h2 className="text-center end-header">
        Please click Submit to complete your form.
      </h2>
    </>
  );
};

export default PhoneCall;

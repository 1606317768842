import React, { useEffect, useState } from "react";

import useScrollTo from "../hooks/useScrollTo";
import SelectInput from "../FormComponents/Components/SelectInput";
import YesNoInput from "../FormComponents/Components/YesNoInput";
import TextInput from "../FormComponents/Components/TextInput";

const privateOrLegalOptions = ["Private", "Legal Aid"];

const AcceptInvitation = ({ state, dispatch }) => {
  const [privateOrLegal, setPrivateOrLegal] = useState(
    state.privateOrLegal || "select"
  );
  const [legalApp, setLegalApp] = useState(state.legalApp);
  const [specificBenefits, setSpecificBenefits] = useState(
    state.specificBenefits
  );
  const [legalReason, setLegalReason] = useState(state.legalReason);
  const [notLegal, setNotLegal] = useState(state.notLegal);
  const [phone, setPhone] = useState(state.phone || "");

  useScrollTo(0, 0, "smooth");

  useEffect(() => {
    dispatch({ type: "PRIVATE_OR_LEGAL", payload: privateOrLegal });
    dispatch({ type: "LEGAL_APP", payload: legalApp });
    dispatch({ type: "SPECIFIC_BENEFITS", payload: specificBenefits });
    dispatch({ type: "LEGAL_REASON", payload: legalReason });
    dispatch({ type: "NOT_LEGAL", payload: notLegal });
    dispatch({ type: "PHONE", payload: phone });
  }, [
    dispatch,
    privateOrLegal,
    legalApp,
    specificBenefits,
    legalReason,
    notLegal,
    phone,
  ]);

  const onPrivateOrLegalChange = ({ target }) => {
    setPrivateOrLegal(target.value);
  };
  const onLegalAppChange = ({ target }) => {
    setLegalApp(target.value);
  };
  const onSpecificBenefitChange = ({ target }) => {
    setSpecificBenefits(target.value);
  };
  const onLegalReasonChange = ({ target }) => {
    setLegalReason(target.value);
  };
  const onNotLegalChange = ({ target }) => {
    setNotLegal(target.value);
  };
  const onPhoneChange = ({ target }) => {
    setPhone(target.value);
  };

  return (
    <>
      <h2>Accepting your invitation to mediation</h2>
      <p>We need further details from you before proceeding......</p>
      <SelectInput
        label="Are you a private client or you think you might be entitled to legal aid?"
        guide="Legal aid is available for people in low income or in receipt of Universal Credit, Income Support, Employment and Support Allowance Income-Related or Jobseeker's allowance."
        options={privateOrLegalOptions}
        optionValue={privateOrLegal}
        required={true}
        onOptionChange={onPrivateOrLegalChange}
      />
      {privateOrLegal === "Legal Aid" && (
        <YesNoInput
          label="Are you willing to make an application for legal aid for family mediation?"
          guide="This means allowing us to contact you about how to apply for legal aid."
          noLabel="No"
          yesLabel="Yes"
          name="legal-application"
          noId="no-legal-application"
          yesId="yes-legal-application"
          required={true}
          checkedTerm={legalApp}
          onOptionChange={onLegalAppChange}
        />
      )}
      {privateOrLegal === "Legal Aid" && legalApp === "Yes" && (
        <YesNoInput
          label="Are you in receipt of any of these specific benefits? (Universal Credit, Income Support, Employment and Support Allowance Income-Related, Jobseeker's allowance)"
          noLabel="No"
          yesLabel="Yes"
          name="specific-benefit"
          noId="no-specific-benefit"
          yesId="yes-specific-benefit"
          required={true}
          checkedTerm={specificBenefits}
          onOptionChange={onSpecificBenefitChange}
        />
      )}
      {privateOrLegal === "Legal Aid" &&
        legalApp === "Yes" &&
        specificBenefits === "No" && (
          <YesNoInput
            label="Do you think you might be entitled to legal aid because you are on a low income/no income/homeless?"
            noLabel="No"
            yesLabel="Yes"
            name="legal-reason"
            yesId="yes-legal-reason"
            noId="no-legal-reason"
            required={true}
            checkedTerm={legalReason}
            onOptionChange={onLegalReasonChange}
          />
        )}
      {privateOrLegal === "Legal Aid" &&
        legalApp === "Yes" &&
        specificBenefits === "No" &&
        legalReason === "No" && (
          <YesNoInput
            label="Because you are not receiving benefits or in low income, it is unlikely that you will qualify for legal aid. Knowing you might not be entitled to legal aid, "
            boldText="would you still like to make an application for legal aid?"
            noLabel="No"
            yesLabel="Yes"
            name="not-legal-aid"
            yesId="yes-legal-aid"
            noId="no-legal-aid"
            required={true}
            checkedTerm={notLegal}
            onOptionChange={onNotLegalChange}
          />
        )}
      <TextInput
        label="What is your telephone number"
        guide="Enter your mobile phone without spaces. Example 07123456789"
        pattern="^\s*\(?(020[7,8]{1}\)?[ ]?[1-9]{1}[0-9{2}[ ]?[0-9]{4})|(0[1-8]{1}[0-9]{3}\)?[ ]?[1-9]{1}[0-9]{2}[ ]?[0-9]{3})\s*$"
        id="phone"
        required={true}
        value={phone}
        onChangeText={onPhoneChange}
      />
    </>
  );
};

export default AcceptInvitation;

import { useEffect, useReducer, useRef, useState } from "react";
import FormComponent from "./FormComponent";
import { defaultState, reducer } from "../Logic/reducer";
import { useMultiStepForm } from "../hooks/useMultiStepForm";
import logo from "../assets/dms-logotype2.png";
import FirstPage from "../Pages/FirstPage";
import AnswerInvitation from "../Pages/AnswerInvitation";
import AcceptInvitation from "../Pages/AcceptInvitation";
import PhoneCall from "../Pages/PhoneCall";
import BenefitLegalAid from "../Pages/BenefitLegalAid";
import LowIncomeLegalAid from "../Pages/LowIncomeLegalAid";
import LegalAidInfo from "../Pages/LegalAidInfo";
import Submitted from "../Pages/Submitted";
import { useParams } from "react-router-dom";

const Form = () => {
  const isMounted = useRef(false);

  const { id } = useParams();

  const [isSubmitted, setIsSubmitted] = useState(false);

  const [formStateClientTwoReply, dispatch] = useReducer(
    reducer,
    JSON.parse(localStorage.getItem("formStateClientTwoReply")) || defaultState
  );

  useEffect(() => {
    if (!isMounted.current) {
      isMounted.current = true;
      return;
    }

    localStorage.setItem(
      "formStateClientTwoReply",
      JSON.stringify(formStateClientTwoReply)
    );
  }, [formStateClientTwoReply]);

  const {
    currentStepIndex,
    steps,
    step,
    isFirstStep,
    isLastStep,
    back,
    next,
    goTo,
  } = useMultiStepForm([
    <FirstPage state={formStateClientTwoReply} dispatch={dispatch} />,
    <AnswerInvitation state={formStateClientTwoReply} dispatch={dispatch} />,
    <AcceptInvitation state={formStateClientTwoReply} dispatch={dispatch} />,
    <PhoneCall state={formStateClientTwoReply} dispatch={dispatch} />,
    <BenefitLegalAid />,
    <LowIncomeLegalAid />,
    <LegalAidInfo />,
  ]);

  console.log({ formStateClientTwoReply });

  return (
    <>
      {isSubmitted === true ? (
        <Submitted />
      ) : (
        <FormComponent
          logo={logo}
          currentStepIndex={currentStepIndex}
          formStateClientTwoReply={formStateClientTwoReply}
          isFirstStep={isFirstStep}
          isLastStep={isLastStep}
          step={step}
          steps={steps}
          goTo={goTo}
          back={back}
          next={next}
          id={id}
          setIsSubmitted={setIsSubmitted}
        />
      )}
    </>
  );
};

export default Form;

import { useState } from "react";

import YesNoInput from "../FormComponents/Components/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";

const FirstPage = ({ state, dispatch }) => {
  const [iConsent, setIConsent] = useState(state.iConsent);

  useScrollTo(0, 0, "smooth");

  const onIConsentChange = ({ target }) => {
    setIConsent(target.value);
    dispatch({ type: "I_CONSENT", payload: target.value });
  };

  return (
    <>
      <div className="page-one-header">
        <h1 className="text-center">Answering your invitation to mediation</h1>
        <p>
          As part of our commitment to your individual rights and freedoms under
          data privacy laws, we need to ask for your consent to collect and use
          the information you provide to us in this form. The information you
          provide in this form will be collected, processed, and used by us in
          accordance with our{" "}
          <a
            href="https://www.directmediationservices.co.uk/wp-content/uploads/2022/09/Privacy-Policy-v-1.1.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
          .
          <br />
          <br />
          After completing each field, please click on the{" "}
          <strong>"Next"</strong> to continue filling the form.
        </p>
      </div>
      <YesNoInput
        label='By selecting "I consent" below, you confirm that you consent to our collection and use of your personal information.'
        name="consent"
        yesId="yes-consent"
        noId="no-consent"
        yesLabel="I consent"
        noLabel="I do not consent"
        checkedTerm={iConsent}
        onOptionChange={onIConsentChange}
        required={true}
      />
    </>
  );
};

export default FirstPage;

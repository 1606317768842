import { client } from "../axios/axios";

export const c2replyPatch = (formStateClientTwoReply, id, currentStepIndex) => {
  const c2replyBody = {
    InvitationAnswer: {
      willingToComeToMediation:
        formStateClientTwoReply.willingToComeToMediation,
      firstName: formStateClientTwoReply.firstName,
      surname: formStateClientTwoReply.surname,
      email: formStateClientTwoReply.email,
      otherPersonFirstName: formStateClientTwoReply.otherPersonFirstName,
      otherPersonSurname: formStateClientTwoReply.otherPersonSurname,
    },
  };
  if (c2replyBody.InvitationAnswer.willingToComeToMediation === "No") {
    c2replyBody.InvitationAnswer.reasonsNotToCome =
      formStateClientTwoReply.reasonsNotToCome;
  }
  if (c2replyBody.InvitationAnswer.willingToComeToMediation === "Yes") {
    c2replyBody.InvitationAccepted = {
      privateOrLegal: formStateClientTwoReply.privateOrLegal,
      phone: formStateClientTwoReply.phone,
    };
    if (c2replyBody.InvitationAccepted.privateOrLegal === "Private") {
      c2replyBody.phoneCallAppointment =
        formStateClientTwoReply.phoneCallAppointment;
    }
    if (c2replyBody.InvitationAccepted.privateOrLegal === "Legal Aid") {
      c2replyBody.InvitationAccepted.willingToMakeLegalAidApplication =
        formStateClientTwoReply.legalApp;
      if (
        c2replyBody.InvitationAccepted.willingToMakeLegalAidApplication ===
        "Yes"
      ) {
        c2replyBody.InvitationAccepted.isReceiptOfAnyOfTheseSpecificBenefits =
          formStateClientTwoReply.specificBenefits;
        if (
          c2replyBody.InvitationAccepted
            .isReceiptOfAnyOfTheseSpecificBenefits === "No"
        ) {
          c2replyBody.InvitationAccepted.isEntitledToLegalAid =
            formStateClientTwoReply.legalReason;
          if (c2replyBody.InvitationAccepted.isEntitledToLegalAid === "No") {
            c2replyBody.InvitationAccepted.isStillLikeToMakeAnApplicationForLegalAid =
              formStateClientTwoReply.notLegal;
          }
        }
      }
    }
  }

  console.log({ c2replyBody });

  client
    .patch(`C2_invitation/${id}`, c2replyBody, {
      headers: { "Content-Type": "application/json" },
    })
    .then((res) => {
      if (currentStepIndex === 4) {
        window.location.replace(
          `https://passport-benefits.vercel.app/PASSPORTING/C2/${id}`
        );
      } else if (currentStepIndex === 5) {
        window.location.replace(
          `https://laa-low-income.vercel.app/LOWINCOME_NOINCOME/C2/${id}`
        );
      } else if (currentStepIndex === 6) {
        window.location.replace(
          "https://www.directmediationservices.co.uk/mediation-legal-aid/"
        );
      }
      console.log({ resData: res.data });
    })
    .catch((err) => console.log({ err }));
};

const YesNoInput = ({
  onOptionChange,
  checkedTerm,
  name,
  noLabel,
  yesLabel,
  label,
  required,
  yesId,
  noId,
  guide,
  boldText,
}) => {
  return (
    <div className="form-control colum-direction">
      <label>
        {label}
        {boldText && <strong>{boldText}</strong>}
        {required && <span className="color-red">*</span>}
      </label>
      {guide && <p className="guide-label">{guide}</p>}
      <div className="radio-style">
        <label htmlFor={yesId} className="label-control">
          <input
            type="radio"
            name={name}
            id={yesId}
            value={yesLabel}
            checked={checkedTerm === yesLabel}
            onChange={onOptionChange}
            required={required}
          />
          {yesLabel}
        </label>
        <label htmlFor={noId} className="label-control">
          <input
            type="radio"
            name={name}
            id={noId}
            value={noLabel}
            checked={checkedTerm === noLabel}
            onChange={onOptionChange}
          />
          {noLabel}
        </label>
      </div>
    </div>
  );
};

export default YesNoInput;

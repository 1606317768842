import { c2replyPatch } from "./patchC2Reply";

export const onBackHandler = (
  e,
  currentStepIndex,
  formStateClientTwoReply,
  back,
  goTo
) => {
  e.preventDefault();
  back();
  if (
    currentStepIndex === 3 &&
    formStateClientTwoReply.willingToComeToMediation === "No"
  ) {
    goTo(1);
  }
  if (
    currentStepIndex === 4 &&
    formStateClientTwoReply.willingToComeToMediation === "Yes" &&
    formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
    formStateClientTwoReply.legalApp === "Yes" &&
    formStateClientTwoReply.specificBenefits === "Yes"
  ) {
    goTo(2);
  }
  if (
    currentStepIndex === 5 &&
    formStateClientTwoReply.willingToComeToMediation === "Yes" &&
    formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
    formStateClientTwoReply.legalApp === "Yes" &&
    formStateClientTwoReply.specificBenefits === "No" &&
    formStateClientTwoReply.legalReason === "Yes"
  ) {
    goTo(2);
  }
  if (
    currentStepIndex === 6 &&
    formStateClientTwoReply.willingToComeToMediation === "Yes" &&
    formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
    formStateClientTwoReply.legalApp === "Yes" &&
    formStateClientTwoReply.specificBenefits === "No" &&
    formStateClientTwoReply.legalReason === "No" &&
    formStateClientTwoReply.notLegal === "Yes"
  ) {
    goTo(2);
  }
};

export const onNextHandler = (
  e,
  currentStepIndex,
  formStateClientTwoReply,
  next,
  goTo,
  setIsSubmitted,
  id
) => {
  e.preventDefault();
  next();
  if (
    currentStepIndex === 1 &&
    formStateClientTwoReply.willingToComeToMediation === "No"
  ) {
    setIsSubmitted(true);
    c2replyPatch(formStateClientTwoReply, id);
  } else if (
    currentStepIndex === 1 &&
    formStateClientTwoReply.willingToComeToMediation === "Yes"
  ) {
    goTo(2);
  }
  if (currentStepIndex === 2) {
    if (
      formStateClientTwoReply.willingToComeToMediation === "Yes" &&
      formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
      formStateClientTwoReply.legalApp === "Yes" &&
      formStateClientTwoReply.specificBenefits === "Yes"
    ) {
      goTo(4);
    } else if (
      formStateClientTwoReply.willingToComeToMediation === "Yes" &&
      formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
      formStateClientTwoReply.legalApp === "Yes" &&
      formStateClientTwoReply.specificBenefits === "No" &&
      formStateClientTwoReply.legalReason === "Yes"
    ) {
      goTo(5);
    } else if (
      formStateClientTwoReply.willingToComeToMediation === "Yes" &&
      formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
      formStateClientTwoReply.legalApp === "Yes" &&
      formStateClientTwoReply.specificBenefits === "No" &&
      formStateClientTwoReply.legalReason === "No" &&
      formStateClientTwoReply.notLegal === "Yes"
    ) {
      goTo(6);
    } else {
      goTo(3);
    }
  }
  if (currentStepIndex === 3) {
    c2replyPatch(formStateClientTwoReply, id);
    setIsSubmitted(true);
  }
  if (
    currentStepIndex === 4 ||
    currentStepIndex === 5 ||
    currentStepIndex === 6
  ) {
    if (currentStepIndex === 4) {
      goTo(4);
    }
    if (currentStepIndex === 5) {
      goTo(5);
    }
    if (currentStepIndex === 6) {
      goTo(6);
    }
    c2replyPatch(formStateClientTwoReply, id, currentStepIndex);
  }
};

export const reducer = (state, { type, payload }) => {
  console.log({ state, type, payload });
  switch (type) {
    case "I_CONSENT":
      return { ...state, iConsent: payload };
    case "WILLING_TO_COME_TO_MEDIATION":
      return { ...state, willingToComeToMediation: payload };
    case "FIRST_NAME":
      return { ...state, firstName: payload };
    case "SURNAME":
      return { ...state, surname: payload };
    case "EMAIL":
      return { ...state, email: payload };
    case "OTHER_PERSON_FIRST_NAME":
      return { ...state, otherPersonFirstName: payload };
    case "OTHER_PERSON_SURNAME":
      return { ...state, otherPersonSurname: payload };
    case "REASON_NOT_TO_COME":
      return { ...state, reasonsNotToCome: payload };
    case "PRIVATE_OR_LEGAL":
      return { ...state, privateOrLegal: payload };
    case "LEGAL_APP":
      return { ...state, legalApp: payload };
    case "SPECIFIC_BENEFITS":
      return { ...state, specificBenefits: payload };
    case "LEGAL_REASON":
      return { ...state, legalReason: payload };
    case "NOT_LEGAL":
      return { ...state, notLegal: payload };
    case "PHONE":
      return { ...state, phone: payload };
    case "PHONE_CALL_APPOINTMENT":
      return { ...state, phoneCallAppointment: payload };
    default:
      break;
  }
};

export const defaultState = { iConsent: "I consent" };

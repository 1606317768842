const LegalAidInfo = () => {
  return (
    <>
      <div className="redirect-page">
        <p className="text-center mx-10">Thank you for your response.</p>
        <h3 className="text-center mx-20">
          After clicking on submit, you will be redirected to the Legal Aid
          information webpage.
        </h3>
        <p className="text-center mx-20">
          You simply need to submit your evidence according to the information
          provided. One of our team will get in touch with you once your
          application is approved.
        </p>
        <p className="text-center mx-20">
          For any applications, you have FIVE working days to submit your
          application. If no submission is received, we will close your case and
          sent court forms to your ex-partner.
        </p>
      </div>
    </>
  );
};

export default LegalAidInfo;

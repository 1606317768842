import React from "react";
import { onBackHandler, onNextHandler } from "../Logic/navFunctions";

const FormComponent = ({
  logo,
  back,
  currentStepIndex,
  step,
  formStateClientTwoReply,
  isFirstStep,
  next,
  goTo,
  setIsSubmitted,
  id,
}) => {
  return (
    <>
      <div className="full-screen">
        <div className="form-screen">
          <div className="yellow-bar"></div>
          <header>
            <img src={logo} alt="Logo" />
          </header>
          <form
            className="form"
            onSubmit={(e) =>
              onNextHandler(
                e,
                currentStepIndex,
                formStateClientTwoReply,
                next,
                goTo,
                setIsSubmitted,
                id
              )
            }
          >
            {step}
            {formStateClientTwoReply.iConsent !== "I consent" && (
              <div className="danger">
                <h2>Ok. This makes it a little hard to continue...</h2>
                <p>
                  Because you don't agree to our data protection policy, we we
                  are unable to assist you at this time.
                  <strong>Your application won't be submitted. </strong>
                </p>
              </div>
            )}
            <div>
              <div className="submit-btn submit">
                {!isFirstStep && (
                  <button
                    type="button"
                    className="back-btn"
                    onClick={(e) =>
                      onBackHandler(
                        e,
                        currentStepIndex,
                        formStateClientTwoReply,
                        back,
                        goTo
                      )
                    }
                  >
                    Back
                  </button>
                )}
                <button
                  type="submit"
                  className="next-btn"
                  disabled={
                    currentStepIndex === 0 &&
                    formStateClientTwoReply.iConsent !== "I consent"
                  }
                  style={{
                    filter:
                      currentStepIndex === 0 &&
                      formStateClientTwoReply.iConsent !== "I consent" &&
                      "opacity(.4)",
                  }}
                >
                  {currentStepIndex > 3 ||
                  (currentStepIndex === 1 &&
                    formStateClientTwoReply.willingToComeToMediation === "No")
                    ? "Submit"
                    : "Next"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default FormComponent;

/**
 *  (currentStepIndex === 2 &&
                    formStateClientTwoReply.willingToComeToMediation ===
                      "Yes" &&
                    formStateClientTwoReply.privateOrLegal === "Legal Aid" &&
                    formStateClientTwoReply.legalApp === "Yes" &&
                    formStateClientTwoReply.notLegal === "Yes") ||
 */

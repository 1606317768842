import React, { useState } from "react";

import YesNoInput from "../FormComponents/Components/YesNoInput";
import useScrollTo from "../hooks/useScrollTo";
import TextInput from "../FormComponents/Components/TextInput";

const AnswerInvitation = ({ state, dispatch }) => {
  const [willingToComeToMediation, setWillingToComeToMediation] = useState(
    state.willingToComeToMediation
  );
  const [firstName, setFirstName] = useState(state.firstName || "");
  const [surname, setSurname] = useState(state.surname || "");
  const [email, setEmail] = useState(state.email || "");
  const [otherPersonFirstName, setOtherPersonFirstName] = useState(
    state.otherPersonFirstName || ""
  );
  const [otherPersonSurname, setOtherPersonSurname] = useState(
    state.otherPersonSurname || ""
  );
  const [reasonsNotToCome, setReasonsNotToCome] = useState(
    state.reasonsNotToCome || ""
  );

  useScrollTo(0, 0, "smooth");

  const onWillingToComeToMediationChange = ({ target }) => {
    setWillingToComeToMediation(target.value);
    dispatch({ type: "WILLING_TO_COME_TO_MEDIATION", payload: target.value });
  };
  const onFirstNameChange = ({ target }) => {
    setFirstName(target.value);
    dispatch({ type: "FIRST_NAME", payload: target.value });
  };
  const onSurnameChange = ({ target }) => {
    setSurname(target.value);
    dispatch({ type: "SURNAME", payload: target.value });
  };
  const onEmailChange = ({ target }) => {
    setEmail(target.value);
    dispatch({ type: "EMAIL", payload: target.value });
  };
  const onOtherPersonFirstNameChange = ({ target }) => {
    setOtherPersonFirstName(target.value);
    dispatch({ type: "OTHER_PERSON_FIRST_NAME", payload: target.value });
  };
  const onOtherPersonSurnameChange = ({ target }) => {
    setOtherPersonSurname(target.value);
    dispatch({ type: "OTHER_PERSON_SURNAME", payload: target.value });
  };
  const onReasonNotToComeChange = ({ target }) => {
    setReasonsNotToCome(target.value);
    dispatch({ type: "REASON_NOT_TO_COME", payload: target.value });
  };

  return (
    <>
      <h2>Answering your invitation to mediation</h2>
      <YesNoInput
        label="Are you willing to come to mediation?"
        yesLabel="Yes"
        noLabel="No"
        name="willingToComeToMediation"
        noId="no-willingToComeToMediation"
        yesId="yes-willingToComeToMediation"
        required={true}
        checkedTerm={willingToComeToMediation}
        onOptionChange={onWillingToComeToMediationChange}
      />
      <div className="big-screen-layout">
        <TextInput
          label="Please provide your first name"
          id="firstName"
          required={true}
          value={firstName}
          onChangeText={onFirstNameChange}
        />
        <TextInput
          label="Please provide your surname/family name"
          id="surname"
          required={true}
          value={surname}
          onChangeText={onSurnameChange}
        />
      </div>
      <div className="form-control colum-direction">
        <label htmlFor="email">
          Please provide your email address
          <span className="color-red">*</span>
        </label>
        <div className="guide-label">
          Don't worry, this will be kept confidential and will not be passed to
          anyone.
        </div>
        <input
          type="email"
          id="email"
          value={email}
          onChange={onEmailChange}
          required={true}
          spellCheck="true"
        />
      </div>
      <div className="big-screen-layout">
        <TextInput
          label="What is the other person in the conflict first name?"
          id="otherPerson-firstName"
          required={true}
          value={otherPersonFirstName}
          onChangeText={onOtherPersonFirstNameChange}
        />
        <TextInput
          label="What is the other person in the conflict surname/family name?"
          id="otherPerson-surname"
          required={true}
          value={otherPersonSurname}
          onChangeText={onOtherPersonSurnameChange}
        />
      </div>
      {willingToComeToMediation === "No" && (
        <TextInput
          label="If you wish, please provide reasons why you are not willing to come to mediation"
          id="reason-not-to-come"
          value={reasonsNotToCome}
          onChangeText={onReasonNotToComeChange}
          guide="This is not a compulsory question."
        />
      )}
    </>
  );
};

export default AnswerInvitation;
